import React, {useState} from 'react';
import {Button, FloatingLabel, Form, Modal, Spinner} from "react-bootstrap";
import axios from "axios";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {POST_URL} from "../../utilities/urls";
import OriginalPost from "./original-post";

const CreateRepost = ({show, handleClose, originalPost}) => {
    const navigate = useNavigate();

    const userProfile = useSelector(state => state.userProfile)

    const [showSpinner, setShowSpinner] = useState(false);

    const onFormSubmit = e => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        const formData = new FormData(e.target);

        setShowSpinner(true);

        const options = {
            method: 'POST',
            url: POST_URL + '/v1/post',
            data: {
                content: formData.get("content").trim(),
                postId: originalPost.postId
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            handleClose();
        }).catch(function (error) {
            setShowSpinner(false);
            console.error(error);
        });
    }

    return (<Modal centered show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Repost</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onFormSubmit} className="mx-3">
                    <Form.Group className="mb-3" controlId="content">
                        <FloatingLabel controlId="content" label="What's on your mind?">
                            <Form.Control
                                name="content"
                                as="textarea"
                                placeholder="What's on your mind?"
                                style={{height: '100px'}}
                            />
                        </FloatingLabel>
                    </Form.Group>

                    <div className="mb-3">
                        <OriginalPost originalPost={originalPost} translate={false}/>
                    </div>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        {
                            showSpinner ? <Spinner animation="border" className="repost-spinner"/> :
                                <Button variant="primary" type="submit" name="create">
                                    Repost
                                </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal.Body>

        </Modal>
    );
}

export default CreateRepost;