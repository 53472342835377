import React from 'react'
import {Outlet, useLocation, useOutletContext} from "react-router-dom";
import {Helmet} from "react-helmet";
import TrendingPostsNavLinks from "../../components/postup/trending-nav-links";

const Trending = () => {
    const contextObj = useOutletContext();
    const location = useLocation();

    return (
        <>
            <Helmet>
                <title>Trending</title>
                <meta name="description" content="Trending"/>
                <meta property="og:title" content="Trending"/>
                <meta property="og:description" content="Trending"/>
            </Helmet>
            <TrendingPostsNavLinks location={location}/>
            <Outlet
                context={contextObj}/>
        </>
    )
}

export default Trending