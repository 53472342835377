import React, {useState} from 'react'
import Header from '../../components/common/header'
import {Outlet, ScrollRestoration, useMatch} from 'react-router-dom'
import AuthModal from "../../components/postup/auth-modal";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import ReportPost from "../../components/postup/report-post";
import LanguageSelection from "../../components/postup/language-selection";
import ScrollToTop from "react-scroll-to-top";
import ScrollUpSvg from "../../components/postup/scroll-up-svg";
import DeletePost from "../../components/postup/delete-post";
import EditPost from "../../components/postup/edit-post";
import CreateRepost from "../../components/postup/create-repost";

const PostsContainer = () => {
    const preferredLanguage = useSelector(state => state.preferredLanguage);
    const userProfile = useSelector(state => state.userProfile);

    const [followingPosts, setFollowingPosts] = React.useState(null);
    const [followingPage, setFollowingPage] = React.useState(1);

    const [topPosts, setTopPosts] = React.useState(null);
    const [topPage, setTopPage] = React.useState(1);

    const [trendingPosts, setTrendingPosts] = React.useState(null);
    const [trendingPage, setTrendingPage] = React.useState(1);

    const [localTrendingPosts, setLocalTrendingPosts] = React.useState(null);
    const [localTrendingPage, setLocalTrendingPage] = React.useState(1);

    const [tagPosts, setTagPosts] = React.useState(null);
    const [tagPage, setTagPage] = React.useState(1);

    const [isAuthScreen, setIsAuthScreen] = useState(false);
    const [authScreenType, setAuthScreenType] = useState(null);

    const [reportPost, setReportPost] = useState(false);

    const [reportPostId, setReportPostId] = useState(null);

    const [signInKey, setSignInKey] = useState(Date.now());

    const [languageSelection, setLanguageSelection] = useState(false);
    const [languageSelectionKey, setLanguageSelectionKey] = useState(10 + Date.now());

    const [authCallBack, setAuthCallBack] = useState(null);
    const [languageSelectionCallBack, setLanguageSelectionCallBack] = useState(null);

    const [deletePost, setDeletePost] = useState(false);
    const [deletePostId, setDeletePostId] = useState(null);
    const [deletePostCallback, setDeletePostCallback] = useState(null);
    const [deletePostKey, setDeletePostKey] = useState(30 + Date.now());

    const [editPost, setEditPost] = useState(false);
    const [editPostId, setEditPostId] = useState(null);
    const [editPostKey, setEditPostKey] = useState(50 + Date.now());

    const [repost, setRepost] = useState(false);
    const [originalPost, setOriginalPost] = useState(null);
    const [repostKey, setRepostKey] = useState(60 + Date.now());

    const showAuth = (callback) => {
        setAuthCallBack(() => callback);
        setIsAuthScreen(true);
    }

    const setAuthScreen = (screen, callback) => {
        setAuthCallBack(() => callback);
        setAuthScreenType(screen);
        setIsAuthScreen(true);
    }

    const closeSignUp = () => {
        setIsAuthScreen(false);
        setAuthScreenType(null);
        setSignInKey(Date.now());
    }

    const showReportPost = (postId) => {
        setReportPostId(postId);
        setReportPost(true);
    }

    const closeReportPost = () => {
        setReportPost(false);
    }

    const closeLanguageSelection = () => {
        setLanguageSelection(false);
        setLanguageSelectionKey(10 + Date.now());
    }
    const showLanguageSelection = (callBacK) => {
        setLanguageSelectionCallBack(() => callBacK);
        setLanguageSelection(true);
    }

    const showDeletePost = (postId, callback) => {
        setDeletePostId(postId);
        setDeletePostCallback(() => callback);
        setDeletePost(true);
    }

    const closeDeletePost = () => {
        setDeletePost(false);
        setDeletePostKey(30 + Date.now());
    }

    const showEditPost = (postId) => {
        setEditPost(true);
        setEditPostId(postId);
    }

    const closeEditPost = () => {
        setEditPost(false);
        setEditPostKey(30 + Date.now());
    }

    const showRepost = (originalPost) => {
        setRepost(true);
        setOriginalPost(originalPost);
    }

    const closeRepost = () => {
        setRepost(false);
        setOriginalPost(null);
        setRepostKey(60 + Date.now());
    }

    const contextObj = {
        followingPosts,
        setFollowingPosts,
        followingPage,
        setFollowingPage,
        topPosts,
        setTopPosts,
        topPage,
        setTopPage,
        trendingPosts,
        setTrendingPosts,
        trendingPage,
        setTrendingPage,
        localTrendingPosts,
        setLocalTrendingPosts,
        localTrendingPage,
        setLocalTrendingPage,
        tagPosts,
        setTagPosts,
        tagPage,
        setTagPage,
        showAuth,
        setAuthScreen,
        showReportPost,
        preferredLanguage,
        userProfile,
        showLanguageSelection,
        showDeletePost,
        closeDeletePost,
        showEditPost,
        editPostKey,
        showRepost
    }

    const match = useMatch("/:childPath/*");
    const childPath = match?.params.childPath ?? "/";

    const scrollToTop = (smooth) => {
        if (childPath === "/") {
            setFollowingPosts(null);
            setFollowingPage(1);
        } else if (childPath === "top") {
            setTopPosts(null);
            setTopPage(1);
        } else if (childPath === "trending") {
            setTrendingPosts(null);
            setTrendingPage(1);
            setLocalTrendingPosts(null);
            setLocalTrendingPage(1);
        }
        if (smooth) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        } else {
            document.documentElement.scrollTop = 0;
        }
    };

    const clear = () => {
        setFollowingPosts(null);
        setFollowingPage(1);
        setTopPosts(null);
        setTopPage(1);
        setTrendingPosts(null);
        setTrendingPage(1);
        setLocalTrendingPosts({});
        setLocalTrendingPage(1);
        setTagPosts(null);
        setTagPage(1);
    }

    return (
        <>
            <Helmet>
                <title>PostUp</title>
                <link rel="image_src" content="https://media.postup.in/images/img_src.jpg"/>
                <link rel="canonical" href="https://postup.in/"/>
                <link rel="shortcut icon" href="./favicon.ico"/>
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                <meta name="description" content="The ultimate social hub!"/>
                <meta property="og:title" content="PostUp"/>
                <meta property="og:description" content="The ultimate social hub!"/>
                <meta property="og:site_name" content="PostUp"/>
                <meta property="og:image" content="https://media.postup.in/images/img_src.jpg"/>
                <meta property="og:url" content="https://postup.in/"/>
                <meta property="og:type" content="website"/>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
            </Helmet>

            <Header showAuth={showAuth} setAuthScreen={setAuthScreen} clear={clear}/>
            <AuthModal key={signInKey} show={isAuthScreen} authScreen={authScreenType}
                       handleClose={closeSignUp} authCallBack={authCallBack}/>
            <DeletePost key={deletePostKey} show={deletePost} handleClose={closeDeletePost} postId={deletePostId}
                        callback={deletePostCallback}/>
            <EditPost key={editPostKey} show={editPost} handleClose={closeEditPost} postId={editPostId}/>
            <ReportPost show={reportPost} handleClose={closeReportPost} postId={reportPostId}/>
            <LanguageSelection key={languageSelectionKey} handleClose={closeLanguageSelection}
                               show={languageSelection} callBack={languageSelectionCallBack}/>
            <CreateRepost key={repostKey} show={repost} handleClose={closeRepost} originalPost={originalPost}/>
            <ScrollRestoration
                getKey={(location, matches) => {
                    return location.pathname;
                }}
            />
            <div className="main-content">
                <Outlet
                    context={contextObj}/>
                <ScrollToTop smooth component={<ScrollUpSvg/>}
                             style={{
                                 borderRadius: "20px",
                                 backgroundColor: "#1877f2",
                                 boxShadow: "none",
                                 bottom: "100px",
                                 right: "30px"
                             }}
                             top={1000}
                             onClick={() => scrollToTop(true)}
                />
            </div>
            {/*<Footer/>*/}
        </>
    )
}

export default PostsContainer
