import axios from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const tenorAxiosInstance = axios.create();

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const getAge = (date) => {
    const strippedDate = date.replace('T', ' ') + ' UTC';
    const jsDate = dayjs.utc(strippedDate.substring(0, strippedDate.indexOf(".")));

    return dayjs(jsDate).fromNow();
}
export const formatDate = (date) => {
    const strippedDate = date.replace('T', ' ') + ' UTC';

    return dayjs(strippedDate).format('h:mm a');
}

export const defaultAvatar = "https://media.postup.in/avatar/user_profile_1.webp";

export const TENOR_KEY = process.env.REACT_APP_TENOR_KEY || "EMPTY";

export const verifyUserProfile = (userProfile, showAuth, setAuthScreen, callback, isValidatePreferences) => {
    if (userProfile) {
        if (userProfile.roles.includes("ROLE_UNVERIFIED_USER")) {
            setAuthScreen(AUTH_SCREEN.EMAIL_VERIFICATION, callback);
        } else if (isValidatePreferences && (!userProfile.preferredLanguage || !userProfile.stateId)) {
            setAuthScreen(AUTH_SCREEN.PREFERENCES, callback);
        } else {
            callback();
        }
    } else {
        showAuth(callback);
    }
}

export const AUTH_SCREEN = {
    SIGN_IN: "SIGN_IN",
    EMAIL_VERIFICATION: "EMAIL_VERIFICATION",
    PREFERENCES: "PREFERENCES"
}

export const SOMETHING_WENT_WRONG = "Something went wrong on our end. Please try again later.";

export const UNABLE_TO_CONNECT = "We're having trouble connecting to our server. Please try again later.";

export const getDomain = (url) => new URL(url).hostname;