import React, {useRef, useState} from 'react';
import Card from "../common/card";
import {Badge} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactPlayer from 'react-player'
import VisibilitySensor from 'react-visibility-sensor';
import Poll from "./poll";
import {defaultAvatar, getAge, getDomain} from "../../utilities/util";
import LinesEllipsis from "react-lines-ellipsis";

const OriginalPost = ({
                          originalPost,
                          translatePost,
                          showAuth,
                          setAuthScreen,
                          preferredLanguage,
                          userProfile,
                      }) => {
    const imageRef = useRef();

    const [visibility, setVisibility] = useState(false);
    const [translatedContent, setTranslatedContent] = useState(null);
    const [translatedImage, setTranslatedImage] = useState(null);
    const [useEllipsis, setUseEllipsis] = useState(true);

    const translate = (preferredLanguage) => {
        if (translatedContent || translatedImage) {
            setTranslatedContent(null);
            setTranslatedImage(null);
        } else if (originalPost.translatedContents || originalPost.media?.translatedMedia) {
            const translated = originalPost.translatedContents?.filter(d => d.languageCode === preferredLanguage);
            if (translated != null && translated.length > 0) {
                setTranslatedContent(translated[0].content);
            }

            const translatedMedia = originalPost.media?.translatedMedia?.filter(d => d.languageCode === preferredLanguage);
            if (translatedMedia != null && translatedMedia.length > 0) {
                setTranslatedImage(translatedMedia[0].media.url);
            }
        }
    }

    if (translatePost) {
        translate(preferredLanguage);
    }

    return (
        originalPost &&
        <Card className="card-block card-stretch card-height card-repost" id={originalPost.postId}>
            <Card.Body>
                <div className="user-post-data">
                    <div className="d-flex">
                        <Link to={`/creator/${originalPost.createdBy}`} className="me-2">
                            <img className="avatar rounded-circle img-fluid"
                                 src={originalPost.creator.avatarUrl || defaultAvatar} alt=""/>
                        </Link>
                        <div className="mb-0 ms-1 d-flex flex-wrap flex-column displayname-block">
                            <Link to={`/creator/${originalPost.createdBy}`} className="d-flex">
                                <div className="mb-0 d-inline-block color-white" style={{fontWeight: 600}}>
                                    {originalPost.creator.displayName}
                                </div>
                            </Link>

                            <div className="time-ago">
                                {getAge(originalPost.createdDate)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-1 mt-1 white-space-wrap">
                    {
                        useEllipsis ?
                            <LinesEllipsis
                                text={translatedContent ? translatedContent : originalPost.content}
                                maxLine='5'
                                ellipsis=' ...read more'
                                basedOn="words"
                                onClick={() => setUseEllipsis(false)}
                            /> :
                            <>
                                {translatedContent ? translatedContent : originalPost.content}
                            </>
                    }

                    {
                        originalPost.externalLink &&
                        <div className="mb-1 mt-1">
                            <a target="_blank" href={originalPost.externalLink}
                               className="color-secondary-blue">{getDomain(originalPost.externalLink)}</a>
                        </div>
                    }
                </div>

                {
                    originalPost.media &&
                    <div className="post-image">
                        {originalPost.media.type === "image" ?
                            <Link to={`/post/${originalPost.postId}`}>
                                <img src={translatedImage ? translatedImage : originalPost.media.url}
                                     alt={originalPost.postId}
                                     className="img-fluid image"
                                     ref={imageRef}/>
                            </Link> :
                            (<VisibilitySensor
                                onChange={(isVisible) => {
                                    setVisibility(isVisible)
                                }}
                            >
                                <ReactPlayer url={originalPost.media.url} controls playing={visibility}
                                             muted={true} width="100%"/>
                            </VisibilitySensor>)}

                    </div>
                }
                {
                    originalPost.type === "poll" &&
                    <Poll post={originalPost} userProfile={userProfile} showAuth={showAuth}
                          setAuthScreen={setAuthScreen}/>
                }
                {
                    originalPost.tags &&
                    <div className="mt-2 ms-3">
                        {originalPost.tags.map(tag =>
                            (<Badge key={tag} pill bg="primary" className="ms-1"
                                    style={{fontSize: 0.8 + "rem"}}>
                                <Link to={`/tag/${tag}`} className="tag-link">
                                    {tag}
                                </Link>
                            </Badge>)
                        )}
                    </div>
                }
            </Card.Body>
        </Card>
    )
}

export default OriginalPost;