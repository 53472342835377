import React from 'react'
import Content from "./content";
import {useOutletContext} from "react-router-dom";
import axios from "axios";
import {POSTS_URL} from "../../utilities/urls";

const TrendingLocal = () => {
    const {
        localTrendingPosts,
        setLocalTrendingPosts,
        localTrendingPage,
        setLocalTrendingPage,
        showAuth,
        setAuthScreen,
        showReportPost,
        preferredLanguage,
        userProfile,
        showLanguageSelection,
        showDeletePost,
        showEditPost,
        showRepost
    } = useOutletContext();

    React.useEffect(() => {
        if (localTrendingPosts == null) {
            fetchData(1);
        }
    }, [localTrendingPosts]);

    const fetchData = (page) => {
        const url = POSTS_URL + `/v1/posts?type=trending&userLanguageCode=${userProfile?.preferredLanguage || "en"}&stateId=${userProfile?.stateId || "ap"}&page=${page}`;

        axios.get(url).then((response) => {
            if (localTrendingPosts == null || page === 1) {
                setLocalTrendingPosts(response.data.data.posts);
            } else {
                setLocalTrendingPosts(localTrendingPosts.concat(response.data.data.posts));
            }
            setLocalTrendingPage(response.data.data.page.nextPage);
        });
    }

    const deleteCallback = () => {
        setLocalTrendingPosts(null);
        fetchData(1);
    }

    return (
        <Content posts={localTrendingPosts} nextPage={() => fetchData(localTrendingPage)} pageNumber={localTrendingPage}
                 showAuth={showAuth} setAuthScreen={setAuthScreen}
                 showReportPost={showReportPost} preferredLanguage={preferredLanguage} userProfile={userProfile}
                 deleteCallback={deleteCallback} showLanguageSelection={showLanguageSelection}
                 showDeletePost={showDeletePost} showEditPost={showEditPost} showRepost={showRepost}/>
    )
}

export default TrendingLocal