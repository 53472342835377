import React from 'react'
import Content from "./content";
import {useOutletContext} from "react-router-dom";
import axios from "axios";
import {POSTS_URL} from "../../utilities/urls";

const Following = () => {
    const {
        followingPosts,
        setFollowingPosts,
        followingPage,
        setFollowingPage,
        showAuth,
        setAuthScreen,
        showReportPost,
        preferredLanguage,
        userProfile,
        showLanguageSelection,
        showDeletePost,
        showEditPost,
        showRepost
    } = useOutletContext();

    React.useEffect(() => {
        if (followingPosts === null) {
            fetchData(1);
        }
    }, [followingPosts]);

    const fetchData = (page) => {
        const url = POSTS_URL + `/v1/posts?type=following&page=${page}`;

        axios.get(url).then((response) => {
            if (followingPosts == null || page === 1) {
                setFollowingPosts(response.data.data.posts);
            } else {
                setFollowingPosts(followingPosts.concat(response.data.data.posts));
            }
            setFollowingPage(response.data.data.page.nextPage);
        });
    }

    const deleteCallback = () => {
        setFollowingPosts(null);
        fetchData(1);
    }

    return (
        <Content posts={followingPosts} nextPage={() => fetchData(followingPage)} pageNumber={followingPage}
                 showAuth={showAuth} setAuthScreen={setAuthScreen}
                 showReportPost={showReportPost} deleteCallback={deleteCallback}
                 preferredLanguage={preferredLanguage} userProfile={userProfile} showDeletePost={showDeletePost}
                 showLanguageSelection={showLanguageSelection} showEditPost={showEditPost} showRepost={showRepost}/>
    )
}

export default Following