import React from 'react';
import {Container, Nav} from "react-bootstrap";
import {Link} from 'react-router-dom';

const TrendingPostsNavLinks = ({location}) => {
    return (
        <div className="display-inline">
            <div className="sticky-container container">
                <Nav as="ul" variant="pills"
                     className="align-items-center justify-content-center flex-row nav-items p-0 m-0 position-relative">
                    <Nav.Item as="li" className="col-6 p-0 ">
                        <Link to="/" className={`${location.pathname === '/' ? 'active' : ''} nav-link`}>Local Loop</Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="col-6 p-0">
                        <Link to="/national"
                              className={`${location.pathname === '/national' ? 'active' : ''} nav-link`}>Nation's Attention</Link>
                    </Nav.Item>
                </Nav>
            </div>
        </div>);
}
//Your Neighborhood + Our Nation


export default TrendingPostsNavLinks;