import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Container, Dropdown, Form, Image, Nav, NavItem,} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import CustomToggle from "./dropdowns";
import CreatePost from "../postup/create-post";
import {
    faArrowRightFromBracket,
    faArrowTrendUp,
    faGear,
    faMagnifyingGlass,
    faPen,
    faRocket,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LanguageSelection from "../postup/language-selection";
import {useDispatch, useSelector} from "react-redux";
import {setUserProfile} from "../../store/app/reducers";
import axios from "axios";
import CreateMeme from "../postup/create-meme";
import Create from "../postup/create";
import {USER_URL} from "../../utilities/urls";
import SideBar from "./side-bar";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {defaultAvatar, verifyUserProfile} from "../../utilities/util";

const Header = ({showAuth, setAuthScreen, clear}) => {
    const navButtonRef = useRef();

    let navigate = useNavigate();

    const dispatch = useDispatch();

    const userProfile = useSelector(state => state.userProfile);

    const mql = window.matchMedia(`(min-width: 1280px)`);

    const [sidebarDocked, setSidebarDocked] = useState(mql.matches);

    const mediaQueryChanged = () => {
        setSidebarDocked(mql.matches);
        setSidebarOpen && setSidebarOpen(false);
    }

    useEffect(() => {
        mql.addEventListener('change', mediaQueryChanged);

        return () => {
            mql.removeEventListener('change', mediaQueryChanged);
        };
    }, []);

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [showCreate, setShowCreate] = useState(false);
    const [showCreatePost, setShowCreatePost] = useState(false);
    const [showCreateMeme, setShowCreateMeme] = useState(false);
    const [showLanguageSelection, setShowLanguageSelection] = useState(false);

    const [createPostKey, setCreatePostKey] = useState(1 + Date.now());
    const [createMemeKey, setCreateMemeKey] = useState(2 + Date.now());
    const [languageSelectionKey, setLanguageSelectionKey] = useState(5 + Date.now());

    const closeCreate = () => {
        setShowCreate(false);
    }
    const closeCreatePost = () => {
        setShowCreatePost(false);
        setCreatePostKey(1 + Date.now());
    }
    const closeCreateMeme = () => {
        setShowCreateMeme(false);
        setCreateMemeKey(2 + Date.now());
    }
    const closeLanguageSelection = () => {
        setShowLanguageSelection(false);
        setLanguageSelectionKey(5 + Date.now());
    }

    const handleSignOut = () => {
        dispatch(setUserProfile(null));

        const options = {
            method: 'POST',
            url: USER_URL + '/v1/account/signout',
        };

        axios.request(options).then(function (response) {

        }).catch(function (error) {
            console.error(error);
        });
    };

    const showCreateModal = () => {
        setShowCreate(true);
    }

    const handleCreate = () => {
        verifyUserProfile(userProfile, showAuth, setAuthScreen, showCreateModal, true);
    }

    const onSetSidebarOpen = (open) => {
        if (!sidebarDocked) {

            if (sidebarOpen) {
                document.body.style.overflow = "unset";
            } else {
                document.body.style.overflow = "hidden";
            }
        }
        setSidebarOpen(open);
    }

    const navigateToHome = () => {
        clear();
        navigate("/");
        window.location.reload(false);
    };

    const search = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        navigate(`/search?query=${formData.get("query")}`);
        e.target.reset();
    }

    return (
        <>
            <SideBar sidebarOpen={sidebarOpen} sidebarDocked={sidebarDocked} setSidebarOpen={onSetSidebarOpen}/>
            <CreatePost key={createPostKey} handleClose={closeCreatePost} show={showCreatePost}/>
            <CreateMeme key={createMemeKey} handleClose={closeCreateMeme} show={showCreateMeme}/>
            <Create show={showCreate} handleClose={closeCreate} setShowCreatePost={setShowCreatePost}
                    setShowCreateMeme={setShowCreateMeme}/>
            <LanguageSelection key={languageSelectionKey} handleClose={closeLanguageSelection}
                               show={showLanguageSelection}/>
            <div className="top-navbar">
                <Nav className="nav navbar navbar-expand-lg navbar-light iq-navbar p-2">
                    <Container fluid className="navbar-inner">
                        <div className="d-flex align-items-center">
                            <Link
                                to="#"
                                onClick={navigateToHome}
                                className="d-flex align-items-center gap-2 iq-header-logo"
                            >
                                <div style={{marginTop: ".6rem"}}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={35}
                                        height={35}
                                        viewBox="0 0 1024 1024"
                                    >
                                        <rect width="100%" height="100%" fill="#fff"/>
                                        <g fill="#080d1e">
                                            <path
                                                d="M0 141.6v141.7l2.3-3.3c1.2-1.8 3.3-4.1 4.7-5.2C10.3 272.4 999.9 51 1007.4 51c6.2 0 11.2 2.5 14.4 7l2.2 3.3V0H0zm918.5 256.2C818.4 703.8 813.4 718.7 810 722.2c-4.5 4.5-10.1 6.2-16.3 4.9-2.5-.6-60.7-31.4-136.1-71.9l-131.6-71-31.8 32.2C476.7 634 446 665.1 426 685.5c-20 20.3-37.9 38.1-39.9 39.4-7.6 5.2-19.8 2.2-24.9-6.1-1.1-1.8-20.7-63.1-43.6-136.3-23-73.1-41.8-133-41.9-133.1-.1 0-58.2-31.3-129.2-69.4s-132-71-135.6-73.1c-3.8-2.2-7.6-5.3-8.8-7.1L0 296.5V1024h1024V550.5c0-260.4-.1-473.5-.2-473.5-.2 0-47.5 144.4-105.3 320.8M221.3 610.4c5 1.8 7.6 4.2 9.8 9.1 2.5 5.4 2.4 11.3-.3 16.3-3.1 5.6-120.7 125.1-126 127.9-4.8 2.7-10.4 2.9-15.5.8-5-2.1-7.2-4.2-9.4-9-2.4-5.1-2.4-9.9 0-15 2.6-5.7 122.1-127.1 127.6-129.6 4.7-2.2 8.6-2.3 13.8-.5M586 707.5c3.3 1.7 6.6 5.4 7.9 8.8 1.5 3.9 1.4 11.1-.3 15-.8 1.8-36.4 38.9-84.4 87.7-66.8 68-83.9 84.9-87.5 86.4-7.6 3.2-16.8.5-22-6.6-2.6-3.5-3.4-13-1.6-17.6 1.3-3 23.2-25.5 131.4-135.4 35.5-35.9 38.5-38.7 42.6-39.5 5-1.1 10.2-.6 13.9 1.2m-268 67c8.8 4.5 12.1 16.7 6.9 25.3-1.3 2.2-39.4 41.6-84.7 87.5-89.4 90.8-86 87.7-95.6 86.3-12.6-1.9-19.4-15.4-13.3-26.4 2.1-4 163.5-168.7 168.3-171.9 5.1-3.3 12.8-3.6 18.4-.8"/>
                                            <path
                                                d="M469.3 207.4c-219 49-398.4 89.4-398.7 89.7-.4.3 49 27.3 109.6 59.9 98.5 53 110.5 59.2 112.8 58.3 2.6-1 542.7-278.8 565.5-290.9 6.6-3.5 11.3-6.3 10.5-6.3-.8.1-180.7 40.2-399.7 89.3M717.4 314c-139.1 106.7-253 194.4-253.2 194.9-.3.8 321.7 174.5 322.4 173.9.6-.7 184.4-562.2 184.1-562.5-.2-.1-114.2 87-253.3 193.7"/>
                                            <path
                                                d="M570.5 314c-140.8 72.6-256.3 132.1-256.7 132.2-.8.3 60.3 196.8 61.2 196.8.3 0 8.9-31.1 19-69.2 11.1-41.4 19.3-70.4 20.5-72.3 1.1-1.6 94.2-73.7 206.8-160.1s205.3-157.6 205.9-158.2c.7-.7.8-1.2.3-1.1-.6 0-116.2 59.4-257 131.9"/>
                                            <path
                                                d="M439.6 544.2c-5.6 20-26.7 100.1-26.4 100.8.2.5 18-17 39.6-39l39.2-39.8-7.2-4c-16.7-9.1-43-23.2-43.3-23.2-.2 0-1 2.4-1.9 5.2"/>
                                        </g>
                                    </svg>
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 100 50"
                                    height={60}
                                    width={130}
                                    className="ms-1"
                                >
                                    <path
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            fontSize: 72,
                                            lineHeight: 1,
                                            fontFamily: "Varela",
                                            fontVariantLigatures: "none",
                                            textAlign: "center",
                                            textAnchor: "middle",
                                        }}
                                        d="M411.515-50.256q5.184 0 9.072 1.44 3.888 1.368 5.976 3.744 2.16 2.376 3.168 5.112 1.008 2.664 1.008 5.688t-1.008 5.76q-1.008 2.664-3.168 5.04-2.088 2.376-5.976 3.816-3.888 1.368-9.072 1.368h-13.104V0h-6.552v-50.256zm-.648 25.776q7.344 0 10.296-2.808 3.024-2.808 3.024-6.984t-3.024-6.984q-2.952-2.808-10.296-2.808h-12.456v19.584zm25.698 6.12q0-8.568 5.04-13.824t13.32-5.256q8.352 0 13.392 5.256 5.112 5.256 5.112 13.824t-5.112 13.824Q463.277.72 454.925.72q-8.28 0-13.32-5.256t-5.04-13.824m10.08 9.864q3.6 3.24 8.352 3.24t8.28-3.24q3.6-3.312 3.6-9.864 0-6.624-3.6-9.864-3.528-3.24-8.28-3.24t-8.352 3.24q-3.528 3.24-3.528 9.864 0 6.552 3.528 9.864m49.207-22.968q-4.608 0-6.84 1.44-2.16 1.368-2.16 3.528 0 2.088 2.232 3.24t5.4 1.872q3.24.648 6.408 1.656t5.4 3.456 2.232 6.696-3.672 7.272q-3.6 3.024-10.8 3.024-7.128 0-13.032-3.24v-6.264h.36q5.832 3.456 12.816 3.456 7.992 0 7.992-5.112 0-1.656-2.232-2.664-2.232-1.08-5.4-1.8t-6.336-1.728-5.4-3.384-2.232-6.048q0-5.04 3.6-8.208 3.672-3.168 9.936-3.168t11.664 2.016v5.976h-.504q-4.824-2.016-9.432-2.016m40.642.36h-10.44V-9.36q0 3.024 1.656 3.744 1.367.576 3.527.576t4.752-1.152h.504v5.328Q533.47.72 530.013.72q-10.512 0-10.512-9.504v-22.32h-6.84v-.432l13.392-13.104v7.92h10.44zm44.046-19.152h6.553v31.392q0 4.896-1.872 8.784-1.8 3.816-4.896 6.12Q577.3-1.656 573.7-.432 570.173.72 566.429.72t-7.344-1.152q-3.528-1.224-6.624-3.528-3.024-2.304-4.896-6.12-1.8-3.888-1.8-8.784v-31.392h6.552v31.608q0 6.336 4.176 9.864t9.936 3.528 9.936-3.528 4.176-9.864zm49.883 45.792Q625.24.72 618.255.72T606.16-4.608h-.216V16.56h-6.552v-53.28h5.76l.792 4.608h.216q5.112-5.328 12.096-5.328t12.168 5.184q5.256 5.184 5.256 13.896t-5.256 13.896zM617.32-31.32q-6.48 0-11.376 5.4v15.12q4.896 5.4 11.376 5.4 5.328 0 8.568-3.384t3.24-9.576-3.24-9.576-8.568-3.384"
                                        transform="matrix(.4 0 0 .4 -155 35)"
                                        fill="#fff"
                                    />
                                </svg>
                            </Link>
                        </div>

                        <ul className="navbar navbar-expand-md navbar-list">
                            <Dropdown as="li" className="nav-item d-none d-lg-block">
                                <Dropdown.Toggle
                                    href="#"
                                    as={CustomToggle}
                                    variant="search-toggle d-flex align-items-center"
                                >
                                    <FontAwesomeIcon icon={faMagnifyingGlass} style={{fontSize: 1.2 + "rem"}}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="sub-drop">
                                    <Card className="shadow-none m-0 align-items-center">
                                        <Card.Body className="p-0">
                                            <div className="d-flex align-items-center py-3 px-3"
                                                 style={{minWidth: '240px !important'}}>
                                                <Form onSubmit={search}>
                                                    <Form.Control
                                                        name="query"
                                                        type="text"
                                                        className="text search-input"
                                                        placeholder="Search"
                                                    />
                                                </Form>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Nav.Item as="li">
                                <Button className="mx-1 me-2 d-none d-lg-block" variant="primary"
                                        onClick={handleCreate}>
                                    Create
                                </Button>
                            </Nav.Item>
                            {/*<Nav.Item as="li">
                                <Link to="#" className="d-flex align-items-center"
                                      onClick={() => setShowLanguageSelection(true)}>
                                    <FontAwesomeIcon id="language-translation" icon={faLanguage}
                                                     style={{fontSize: 1.5 + "rem"}}/>
                                </Link>
                            </Nav.Item>*/}
                            {
                                userProfile ?
                                    <Dropdown as="li" className="nav-item user-dropdown me-2">
                                        <Dropdown.Toggle
                                            href="#"
                                            as={CustomToggle}
                                            variant="d-flex align-items-center"
                                        >
                                            <Image
                                                src={userProfile.avatarUrl || defaultAvatar}
                                                className="img-fluid rounded-circle"
                                                alt="user"
                                                loading="lazy"
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="sub-drop caption-menu">
                                            <Card className="shadow-none m-0">
                                                <Card.Header>
                                                    <div className="header-title">
                                                        <h3 className="mb-0">Hello {userProfile.displayName}</h3>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body className="p-0">
                                                    <Dropdown.Item onClick={() => navigate("/user")}
                                                                   className="mb-0 h6">
                                                        <div className="d-flex align-items-center border-0">
                                                            <FontAwesomeIcon icon={faUser}
                                                                             style={{fontSize: 1.3 + "rem"}}/>
                                                            <div className="ms-3">
                                                                Profile
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => navigate("/user/settings")}
                                                                   className="mb-0 h6">
                                                        <div className="d-flex align-items-center border-0">
                                                            <FontAwesomeIcon icon={faGear}
                                                                             style={{fontSize: 1.3 + "rem"}}/>
                                                            <div className="ms-3">
                                                                Settings
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="mb-0 h6"
                                                                   onClick={() => handleSignOut()}>
                                                        <div className="d-flex align-items-center border-0">
                                                            <FontAwesomeIcon icon={faArrowRightFromBracket}
                                                                             style={{fontSize: 1.3 + "rem"}}/>
                                                            <div className="ms-3">
                                                                Sign out
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                </Card.Body>
                                            </Card>
                                        </Dropdown.Menu>
                                    </Dropdown> :
                                    <Link to="#" onClick={() => showAuth()} className="ms-2">Sign in</Link>
                            }
                        </ul>
                    </Container>
                </Nav>
            </div>
            <nav className="navbar fixed-bottom d-block d-lg-none" role="navigation">
                <Nav className="w-100">
                    <div className=" d-flex flex-row justify-content-around w-100">
                        <NavItem>
                            <Link to="/" className="nav-bar-icon feather-icon">
                                <div className="m-2">
                                    <FontAwesomeIcon style={{fontSize: "1.3rem"}} icon={faArrowTrendUp}/>
                                </div>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/following" className="nav-bar-icon feather-icon">
                                <div className="m-2">
                                    <FontAwesomeIcon style={{fontSize: "1.3rem"}} icon={faUsers}/>
                                </div>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/spaces" className="nav-bar-icon feather-icon">
                                <div className="m-2">
                                    <FontAwesomeIcon style={{fontSize: "1.3rem"}} icon={faRocket}/>
                                </div>
                            </Link>
                        </NavItem>
                        <Dropdown className="nav-item">
                            <Dropdown.Toggle
                                href="#"
                                as={CustomToggle}
                                variant="m-2 nav-bar-icon feather-icon"
                            >
                                <FontAwesomeIcon icon={faMagnifyingGlass} style={{fontSize: "1.3rem"}}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="sub-drop">
                                <Card className="shadow-none m-0 align-items-center">
                                    <Card.Body className="p-0">
                                        <div className="d-flex align-items-center py-3 px-3"
                                             style={{minWidth: '250px'}}>
                                            <Form onSubmit={search}>
                                                <Form.Control
                                                    name="query"
                                                    type="text"
                                                    className="text search-input"
                                                    placeholder="Search"
                                                />
                                            </Form>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Dropdown.Menu>
                        </Dropdown>
                        <NavItem>
                            <Link to="#" onClick={handleCreate} className="nav-bar-icon feather-icon">
                                <FontAwesomeIcon style={{fontSize: "1.3rem"}} icon={faPen} className="m-2"/>
                            </Link>
                        </NavItem>
                    </div>
                </Nav>
            </nav>
        </>
    );
};

export default Header;
