import React, {useRef, useState} from 'react'
import {Button, Col, Container, Form, ProgressBar, Row, Spinner} from 'react-bootstrap'
import Card from '../../components/common/card'
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {MEDIA_URL, USER_URL} from "../../utilities/urls";
import PasswordChangeModal from "../../components/auth/password-change-modal";
import {setPreferredLanguage, setUserProfile} from "../../store/app/reducers";
import DeleteAccount from "../../components/postup/delete-account";
import EmailUpdateModal from "../../components/auth/email-update-modal";
import {defaultAvatar} from "../../utilities/util";

const UserSettings = () => {
    let navigate = useNavigate();

    const dispatch = useDispatch();

    const displayNameRef = useRef();

    const userProfile = useSelector(state => state.userProfile);

    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const [passwordResetKey, setPasswordResetKey] = useState(10 + Date.now());

    const [showDeleteAccount, setShowDeleteAccount] = useState(false);
    const [deleteAccountKey, setDeleteAccountKey] = useState(20 + Date.now());

    const [showEmailUpdate, setShowEmailUpdate] = useState(false);
    const [emailUpdateKey, setEmailUpdateKey] = useState(40 + Date.now());

    const [showPasswordSpinner, setShowPasswordSpinner] = useState(false);
    const [showDisplayNameSpinner, setShowDisplayNameSpinner] = useState(false);

    const [emailAddress, setEmailAddress] = useState(userProfile?.emailAddress);

    const [avatar, setAvatar] = useState(userProfile.avatarUrl || defaultAvatar);
    const [showProgressbar, setShowProgressbar] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    React.useEffect(() => {
        if (!userProfile) {
            return navigate('/');
        }
    }, []);

    const updateDisplayName = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        if (userProfile.displayName !== formData.get("displayName")) {
            setShowDisplayNameSpinner(true);

            const options = {
                method: 'PATCH',
                url: USER_URL + '/v1/user',
                data: {
                    displayName: formData.get("displayName")
                }
            };

            axios.request(options).then(function (response) {
                setShowDisplayNameSpinner(false);
                dispatch(setUserProfile(response.data.data));
            }).catch(function (error) {
                setShowDisplayNameSpinner(false);
                console.error(error);
            });
        }

        if (userProfile.emailAddress !== formData.get("emailAddress")) {
            setEmailAddress(formData.get("emailAddress").trim());
            setShowDisplayNameSpinner(true);

            const options = {
                method: 'PATCH',
                url: USER_URL + '/v1/account/email/email',
                data: {
                    emailAddress: formData.get("emailAddress")
                }
            };

            axios.request(options).then(function (response) {
                setShowDisplayNameSpinner(false);
                setShowEmailUpdate(true);
            }).catch(function (error) {
                setShowDisplayNameSpinner(false);
                console.error(error);
            });
        }
    };

    const showPasswordChange = () => {
        setShowPasswordSpinner(true);
        setShowPasswordReset(true);
        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/account/password/email',
            data: {
                userId: userProfile.userId
            }
        };

        axios.request(options).then(function (response) {
            setShowPasswordSpinner(false);
        }).catch(function (error) {
            setShowPasswordSpinner(false);
            console.error(error);
        });
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0]
        const filename = file.name
        const contentType = file.type
        const fileType = 'avatar'

        const options = {
            method: 'POST',
            url: MEDIA_URL + '/v1/media/upload',
            headers: {
                'Content-Type': contentType,
                'File-Name': filename,
                'File-Type': fileType
            },
            data: file, onUploadProgress: progressEvent => {
                const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
                setUploadProgress(uploadProgress);
            }
        };

        setShowProgressbar(true);
        axios.request(options).then(function (response) {
            const options = {
                method: 'PATCH',
                url: USER_URL + '/v1/user',
                data: {
                    avatarPath: response.data.data.path
                }
            };

            axios.request(options).then(function (response) {
                setShowProgressbar(false);
                dispatch(setUserProfile(response.data.data));
                response.data.data.preferredLanguage && dispatch(setPreferredLanguage(response.data.data.preferredLanguage));
            }).catch(function (error) {
                setShowProgressbar(false);
                console.error(error);
            });
        }).catch(function (error) {
            setShowProgressbar(false);
            console.error(error);
        });
    };

    const closePasswordChange = () => {
        setShowPasswordReset(false);
        setPasswordResetKey(Date.now());
    }

    const closeDeleteAccount = () => {
        setShowDeleteAccount(false);
        setDeleteAccountKey(30 + Date.now());
    }

    const closeEmailUpdate = () => {
        setShowEmailUpdate(false);
        setEmailUpdateKey(40 + Date.now());
    }

    return userProfile && (
        <>
            <PasswordChangeModal key={passwordResetKey} show={showPasswordReset} userId={userProfile.userId}
                                 showPasswordSpinner={showPasswordSpinner} handleClose={closePasswordChange}/>
            <EmailUpdateModal key={emailUpdateKey} show={showEmailUpdate} emailAddress={emailAddress}
                              handleClose={closeEmailUpdate}/>
            <DeleteAccount key={deleteAccountKey} show={showDeleteAccount} handleClose={closeDeleteAccount}
                           userProfile={userProfile}/>
            <Container>
                <Row>
                    <Col lg={12}>
                        <Card className="mt-3">
                            <Card.Body className="profile-page p-0">
                                <div className="d-flex flex-column w-100 my-2 p-5">
                                    <h4>Account Settings</h4>
                                    <Form autoComplete="off" onSubmit={updateDisplayName} className="mt-2">
                                        <Form.Group as={Row} className="form-group">
                                            <Form.Label style={{fontSize: "1rem"}} column="true" sm={4}>Email
                                                Address</Form.Label>
                                            <Form.Control type="email" className="mb-0" name="emailAddress"
                                                          ref={displayNameRef} defaultValue={userProfile.emailAddress}
                                                          style={{width: "250px"}} required={true}/>
                                        </Form.Group>
                                        <Form.Group as={Row} className="form-group">
                                            <Form.Label style={{fontSize: "1rem"}} column="true" sm={4}>Display
                                                Name</Form.Label>
                                            <Form.Control type="text" className="mb-0" name="displayName"
                                                          ref={displayNameRef} defaultValue={userProfile.displayName}
                                                          style={{width: "250px"}} required={true} minLength={3}
                                                          maxLength={50}/>
                                        </Form.Group>
                                        <Form.Group as={Row} className="form-group">
                                            <Form.Label className="custom-file-input" column="true" sm={4}>Avatar
                                                Image</Form.Label>
                                            <div style={{width: "250px", height: "6rem"}}>
                                                {showProgressbar ?
                                                    <ProgressBar animated striped now={uploadProgress}></ProgressBar> :
                                                    <>
                                                        <input hidden id="avatar-input" type="file"
                                                               accept="image/*"
                                                               onInputCapture={(event) => handleFileUpload(event)}/>
                                                        <label htmlFor="avatar-input" className="pointer">
                                                            <img src={avatar}
                                                                 className="img-fluid rounded-circle"
                                                                 style={{width: "6rem", height: "6rem"}}/>
                                                        </label>
                                                    </>
                                                }
                                            </div>
                                        </Form.Group>
                                        {
                                            showDisplayNameSpinner ?
                                                <Spinner style={{marginLeft: "3rem", marginTop: ".36rem"}}/> :
                                                <Button type="submit" className="btn-primary">Save Changes</Button>
                                        }
                                    </Form>
                                </div>
                                <div className="p-5 d-flex flex-column" style={{fontSize: "1rem", color: "white"}}>
                                    <div>
                                        <div>Would you like to change your password?</div>
                                        <Button type="submit" className="btn-primary"
                                                onClick={showPasswordChange}>Change Password</Button>
                                    </div>
                                </div>
                                <div className="p-5 d-flex flex-column" style={{fontSize: "1rem", color: "white"}}>
                                    <div>
                                        <Link to="#" style={{color: "var(--bs-primary)"}}
                                              onClick={() => setShowDeleteAccount(true)}>Delete account?</Link>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UserSettings;